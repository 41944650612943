import type { ErrorIdent } from '@/@types/errorIdents';
import { useUserAddress } from '@/stores/useUserAddress';
import useDeleteAddressDialog from '@/composables/dialogs/useDeleteAddressDialog';

export function useDeleteAddress() {
  const deleteAddressDialog = useDeleteAddressDialog();
  const errors = ref<ErrorIdent[]>([]);
  const isLoading = ref(false);

  const address = deleteAddressDialog.getData();

  if (
    !address ||
    address.isContactAddress ||
    address.isDefaultBillingAddress ||
    address.isDefaultShippingAddress
  )
    deleteAddressDialog.close();

  async function deleteAddress() {
    try {
      isLoading.value = true;

      let result = null;
      if (address) {
        if (
          useUserAddress().activeBillingAddress.id === address.id ||
          useUserAddress().activeShippingAddress.id === address.id
        ) {
          await useUserAddress().setActiveAddress({
            shippingAddressId: useUserAddress().contactAddress.id,
            billingAddressId: useUserAddress().contactAddress.id,
          });
        }

        result = await useUserAddress().deleteAddress(address.id);
      }

      if (result) {
        deleteAddressDialog.close();
      }

      isLoading.value = false;
    } catch (e: any) {
      errors.value = e.data.data.errors;
      isLoading.value = false;
    }
  }

  return {
    errors,
    isLoading,
    deleteAddress,
  };
}
